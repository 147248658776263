import React, { useEffect, useRef, useState } from "react";
import { Companions } from "../progress-bar/companion/companion";

import ProgressBar, {
  Backgrounds,
  ProgressColors,
} from "../progress-bar/progress-bar";
import { GameType } from "../game-container/game-container";

export type TimerProgressBarProps = {
  isTimerStarted: boolean | undefined;
  gameDuration: number;
  onTimerEnd: Function;
  gameType: GameType;
  isGameStarted: boolean;
  maxQuestions: number;
};

export const useTimerProgressBar = (props: TimerProgressBarProps) => {
  const [position, setPosition] = useState(1);
  const [progress, setProgress] = useState(0);
  const [showReward, setShowReward] = useState(false);
  const positionRef = useRef(0);
  const progressRef = useRef(0);
  const intervalRef = React.useRef<null | NodeJS.Timeout>(null);
  const intervalTime = props.gameDuration / props.maxQuestions;

  const startTimer = () => {
    setShowReward(false);
    setPosition(1);
    setProgress(0);
    intervalRef.current = setInterval(() => {
      console.log("timer running");
      if (positionRef.current < props.maxQuestions)
        setPosition(positionRef.current + 1);
      setProgress(progressRef.current + 1);

      if (progressRef.current + 1 >= props.maxQuestions) {
        props.onTimerEnd();
        stopTimer(intervalRef.current as NodeJS.Timeout);
        setShowReward(true);
        resetTimer();
      }
    }, intervalTime);
  };

  const stopTimer = (intervalId: NodeJS.Timeout) => {
    clearInterval(intervalId);
  };

  const resetTimer = () => {
    setTimeout(() => {
      setShowReward(false);
      setPosition(1);
      setProgress(0);
    }, 3000);
  };

  return {
    position,
    setPosition,
    progress,
    setProgress,
    showReward,
    setShowReward,
    positionRef,
    progressRef,
    startTimer,
    stopTimer,
    resetTimer,
    intervalRef,
  };
};

function TimerProgressBar(props: TimerProgressBarProps) {
  const {
    position,
    progress,
    showReward,
    positionRef,
    progressRef,
    startTimer,
    stopTimer,
    intervalRef,
  } = useTimerProgressBar(props);

  useEffect(() => {
    const intervalId = intervalRef.current as NodeJS.Timeout;
    return () => {
      stopTimer(intervalId);
      console.log("unmounted");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    positionRef.current = position;
    progressRef.current = progress;
    const progressCompanionContainer = document.getElementsByClassName(
      "companion_container"
    );
    if (props.gameType !== GameType.SpeedOneAir) {
      for (const element of progressCompanionContainer) {
        element.setAttribute("y", "-12px");
      }
    }
  });

  useEffect(() => {
    console.log("timer ended");
    props.isTimerStarted
      ? startTimer()
      : stopTimer(intervalRef.current as NodeJS.Timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isTimerStarted]);

  return (
    <>
      <ProgressBar
        gameType={props.gameType}
        companion={Companions.Chameleon}
        color={ProgressColors.Yellow}
        progress={progress}
        position={position}
        previousBestPosition={0}
        showMask={false}
        showReward={showReward}
        backgroundType={
          props.gameType === GameType.SpeedOneAir
            ? Backgrounds.Air_Timer
            : Backgrounds.Timer
        }
        isGameStarted={props.isGameStarted}
      />
    </>
  );
}

export default TimerProgressBar;

